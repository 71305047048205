import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react"
import { useMeLazyQuery, useMeQuery } from "../../graphql"
const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") ?? ""

interface AuthContextInterface {
  user: MeQuery["me"]
  logout: () => void
  login: ({ email, password }: { email: string; password: string }) => Promise<{ user: any }>
}

type AuthProviderProps = PropsWithChildren<Partial<AuthContextInterface>>

export const AuthContext = createContext<AuthContextInterface | undefined>(undefined)

export function useAuth(): AuthContextInterface {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<MeQuery["me"]>()
  const [getMe] = useMeLazyQuery()

  const logout = () => {
    localStorage.clear()
    window.location.href = "/login"
  }

  const login = async ({ email, password }: { email: string; password: string; fullAuth?: boolean }) => {
    try {
      const response = await fetch(url + "/api/auth/local", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          identifier: email,
        }),
      })

      const data = await response.json()
      const { accessToken, refreshToken, user } = data
      if (user) {
        localStorage.setItem("accessToken", accessToken)
        localStorage.setItem("refreshToken", refreshToken)
        return { user }
      } else {
        throw new Error("Invalid identifier or password")
      }
    } catch (error) {
      const errorMessage = (error as any)?.message
      throw new Error(errorMessage || "Invalid identifier or password")
    }
  }

  useEffect(() => {
    !user &&
      getMe().then(me => {
        if (me?.error?.message == "Forbidden access") return
        const user = me?.data?.me
        if (user?.role?.data?.attributes?.name !== "Salon" || user?.salon?.data?.attributes?.isActive) setUser(user)
      })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
