import { Layout as BaseLayout } from "antd"
import React, { FC, Suspense, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import NoConnection from "../../pages/noConnection"
import { useApp } from "../app"
import { useAuth } from "../app/Auth"
import DesctopNavbar from "../desctopNavbar"

const Layout: FC = () => {
  const [isOffline, setIsOffline] = useState(false)
  const { user } = useAuth()
  const {
    app: { userType },
  } = useApp()

  const handleOfflineEvent = () => {
    setTimeout(() => {
      !navigator.onLine && setIsOffline(true)
    }, 3000)
  }

  const handleRecheckConnection = () => {
    navigator.onLine && setIsOffline(false)
  }

  useEffect(() => {
    window.addEventListener("offline", handleOfflineEvent)
    return () => {
      window.removeEventListener("offline", handleOfflineEvent)
    }
  }, [])

  return (
    <BaseLayout>
      <DesctopNavbar />
      <Suspense>
        {!isOffline ? (
          <Outlet />
        ) : (
          <NoConnection user={user} userType={userType} handleRecheckConnection={handleRecheckConnection} />
        )}
      </Suspense>
    </BaseLayout>
  )
}

export { Layout }
