import React, { FC, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { ReactComponent as Clock } from "../../icons/clock.svg"
import { ReactComponent as Download } from "../../icons/download.svg"
import { ReactComponent as Home } from "../../icons/home.svg"
import { ReactComponent as Info } from "../../icons/info.svg"
import { ReactComponent as Leaderboard } from "../../icons/leaderboard.svg"
import noLogo from "../../icons/logo-avatar.png"
import logo from "../../icons/logo.png"
import { ReactComponent as Logout } from "../../icons/logout.svg"
import { ReactComponent as News } from "../../icons/news.svg"
import { ReactComponent as Play } from "../../icons/play.svg"
import { ReactComponent as Ranking } from "../../icons/ranking.svg"
import ToolTipWrapper from "../../pages/homepage/components/ToolTipWrapper"
import { useApp } from "../app"
import { useAuth } from "../app/Auth"
import "./index.less"

const Index: FC<{ isLogo?: boolean; isPageReady?: boolean }> = () => {
  const {
    app: { userType, isPageReady },
  } = useApp()

  const [avatar, setAvatar] = useState<string>(noLogo)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const handleLogout = () => {
    logout()
  }
  const isAccountPage = pathname.split("/")[1] == "account"

  if (user && isAccountPage) {
    return (
      <div className={"desktop-navbar"} style={{ borderRight: "1px solid black" }}>
        <div className={`logo-wrapper`}>
          <img src={logo} alt={"logo"} style={{ width: "100%", height: "100%" }} />
        </div>
        <nav className={"navbar"}>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"Homepage"}>
              <Home
                onClick={() => {
                  navigate(`/account/homepage`)
                }}
                className={"nav-icon"}
                style={{ opacity: pathname.slice(0, 17) == "/account/homepage" ? "1" : "0.6" }}
              />
            </ToolTipWrapper>
          </div>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"Leaderboard"}>
              <Leaderboard
                onClick={() => {
                  navigate("/account/leaderboard")
                }}
                className={"nav-icon"}
                style={{
                  opacity: pathname.slice(0, 20) == "/account/leaderboard" ? "1" : "0.6",
                }}
              />
            </ToolTipWrapper>
          </div>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"How it works"}>
              <Info
                onClick={() => {
                  navigate("/account/how-it-works/about")
                }}
                className={"nav-icon"}
                style={{ opacity: pathname.slice(0, 21) == "/account/how-it-works" ? "1" : "0.6" }}
              />
            </ToolTipWrapper>
          </div>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"Video library"}>
              <Play
                onClick={() => {
                  navigate("/account/library")
                }}
                className={"nav-icon"}
                style={{ opacity: pathname.slice(0, 16) == "/account/library" ? "1" : "0.6" }}
              />
            </ToolTipWrapper>
          </div>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"Brand updates"}>
              <News
                onClick={() => {
                  navigate("/account/news")
                }}
                className={"nav-icon"}
                style={{ opacity: pathname.slice(0, 13) == "/account/news" ? "1" : "0.6" }}
              />
            </ToolTipWrapper>
          </div>

          {userType !== "salon" && (
            <div className={`${!isPageReady && userType == "salon" && "skeleton"}`}>
              <ToolTipWrapper type={"navbar"} linkName={"Report"}>
                <Download
                  onClick={() => {
                    if (isPageReady || userType !== "salon") {
                      navigate("/account/report")
                    }
                  }}
                  className={"nav-icon"}
                  style={{
                    opacity: pathname.slice(0, 15) == "/account/report" ? "1" : "0.6",
                  }}
                />
              </ToolTipWrapper>
            </div>
          )}
          {userType !== "salon" && (
            <div>
              <ToolTipWrapper type={"navbar"} linkName={"DSC ranking report"}>
                <Ranking
                  onClick={() => {
                    navigate("/account/dsc-ranking")
                  }}
                  className={"nav-icon"}
                  style={{ opacity: pathname.slice(0, 20) == "/account/dsc-ranking" ? "1" : "0.6" }}
                />
              </ToolTipWrapper>
            </div>
          )}
          {userType !== "salon" && (
            <div>
              <ToolTipWrapper type={"navbar"} linkName={"Activity log"}>
                <Clock
                  onClick={() => {
                    navigate("/account/activity")
                  }}
                  className={"nav-icon"}
                  style={{
                    opacity:
                      !isPageReady && userType == "salon"
                        ? "0"
                        : pathname.slice(0, 17) == "/account/activity"
                        ? "1"
                        : "0.6",
                  }}
                />
              </ToolTipWrapper>
            </div>
          )}
        </nav>
        {userType == "salon" ? (
          <div
            className={`image-wrapper`}
            onClick={() => {
              navigate(`/account/profile/account`)
            }}
          >
            <ToolTipWrapper type={"navbar"} linkName={"Profile"}>
              <img src={avatar} alt={"avatar"} style={{ width: "100%", height: "100%" }} />
            </ToolTipWrapper>
          </div>
        ) : (
          <div />
        )}
        <div className={"logout-btn"} onClick={handleLogout}>
          <ToolTipWrapper type={"navbar"} linkName={"Logout"}>
            <Logout />
          </ToolTipWrapper>
        </div>
      </div>
    )
  } else {
    return null
  }
}
export default Index
