import { Tooltip } from "antd"
import React, { FC, ReactNode } from "react"
import { useApp } from "../../../components/app"
interface ToolTipInterface {
  sizeType?: "Full" | "Min"
  sizeSales?: number
  type: "chart" | "verification" | "points" | "salonNumbers" | "totalPoints" | "navbar" | "disabled verification"
  children: ReactNode
  verifiedBy?: string
  verifiedDate?: string | null
  verifiedTime?: string | null
  direction?: "top"
  linkName?: string
  disabled?: boolean
}
const ToolTipWrapper: FC<ToolTipInterface> = ({
  sizeType,
  sizeSales,
  children,
  type,
  verifiedBy,
  verifiedDate,
  verifiedTime,
  direction,
  linkName,
  disabled = false,
}) => {
  const {
    app: { userName },
  } = useApp()

  const title = (
    <div style={{ fontWeight: "300" }}>
      {type === "chart" ? (
        <React.Fragment>
          {sizeType} size - <span style={{ color: "#32D74B" }}>{sizeSales}</span>
        </React.Fragment>
      ) : type === "verification" ? (
        <React.Fragment>
          Verified by {verifiedBy !== userName ? verifiedBy : userName}
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: "#FFF", opacity: 0.35 }}>{verifiedDate}</span>
          </div>
          <span style={{ color: "#FFF", opacity: 0.35 }}>{verifiedTime}</span>
        </React.Fragment>
      ) : type == "points" ? (
        <span>Enter the quantity sold of either full or mini sizes</span>
      ) : type == "salonNumbers" ? (
        <span>Total number of Salons signed up for the tournament </span>
      ) : type == "totalPoints" ? (
        <span>
          <span style={{ color: "#32D74B" }}>Verified</span> and Unverified
        </span>
      ) : type == "navbar" ? (
        <span>{linkName}</span>
      ) : type == "disabled verification" ? (
        <span>
          Verification is disabled,
          <span style={{ color: "#32D74B" }}> Other levels</span> must verify
        </span>
      ) : (
        <div />
      )}
    </div>
  )

  return disabled ? (
    <>{children}</>
  ) : (
    <Tooltip title={title} placement={direction == "top" ? "top" : "left"}>
      {children}
    </Tooltip>
  )
}
export default ToolTipWrapper
